import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f09551c = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _1fc4faa4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _60a3db95 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _445f434c = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _ee18a358 = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _6bfd5942 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _34dd85e1 = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _387b6f6e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _641a370d = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _4e1cde0c = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _5b1acce4 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _83c18624 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _e5714d12 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _d99e32b6 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _1ba331ca = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _3e15eafd = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _35f7fc82 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _2cc871f1 = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _01c8c508 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _76f16dae = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _4ef87c86 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _72c203f4 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _235ea187 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _e8493d22 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _24f4ab38 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _2675df97 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6f6a9bd2 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _32bd303a = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _2a5d119c = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _3f09551c,
    name: "check-email"
  }, {
    path: "/login",
    component: _1fc4faa4,
    name: "login"
  }, {
    path: "/logout",
    component: _60a3db95,
    name: "logout"
  }, {
    path: "/medications",
    component: _445f434c,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _ee18a358,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _6bfd5942,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _34dd85e1,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _387b6f6e,
    name: "register"
  }, {
    path: "/registered",
    component: _641a370d,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _4e1cde0c,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _5b1acce4,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _83c18624,
    name: "settings",
    children: [{
      path: "close-account",
      component: _e5714d12,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _d99e32b6,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _1ba331ca,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _3e15eafd,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _35f7fc82,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _2cc871f1,
      name: "settings-security"
    }, {
      path: "user",
      component: _01c8c508,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _76f16dae,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _4ef87c86,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _72c203f4,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _235ea187,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _e8493d22,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _24f4ab38,
    name: "redirect-new"
  }, {
    path: "/",
    component: _2675df97,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _6f6a9bd2,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _32bd303a,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _2a5d119c,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
