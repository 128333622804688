import { render, staticRenderFns } from "./ContactSlideOver.vue?vue&type=template&id=9e0b7e4e&"
import script from "./ContactSlideOver.vue?vue&type=script&lang=ts&"
export * from "./ContactSlideOver.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ContactSlideOver.vue?vue&type=custom&index=0&blockType=i18n&locale=es&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./ContactSlideOver.vue?vue&type=custom&index=1&blockType=i18n&locale=en&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactForm: require('/tmp/build_b5cc9faf/components/ContactForm.vue').default,SlideOver: require('/tmp/build_b5cc9faf/components/SlideOver.vue').default})
